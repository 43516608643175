import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'views.title.errorPage',
    defaultMessage: 'Oops! {errorCode} ― Tangram',
  },

  OOPS: { id: 'errorPage.oops', defaultMessage: 'Oops...' },
  GO_HOME: { id: 'errorPage.goHome', defaultMessage: 'Go Home' },
  DEFAULT_ERROR: { id: 'errorPage.default', defaultMessage: 'An error occured.' },
});

export const errorMessages = defineMessages({
  401: { id: 'errorPage.401', defaultMessage: 'You have to log in to see this page.' },
  403: { id: 'errorPage.403', defaultMessage: 'You don\'t have access to this resource.' },
  404: { id: 'errorPage.404', defaultMessage: 'The page you are looking for does not exist.' },
  500: { id: 'errorPage.500', defaultMessage: 'Server error, sorry!' },
});
